/*
* Grid modules
*/

// Custom grid
.container {
	@include media-breakpoint-up(xl) {
		max-width: 1800px;
	}

	@include media-breakpoint-up(xxl) {
		max-width: 1800px;
		padding-left: 30px;
		padding-right: 30px;

		.row {
			margin-left: -30px;
			margin-right: -30px;
		}

		[class*="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}
