//Pricing Table or Box
//Pricing Box
.pricing-box {
  padding: 30px 0;
  border-radius: $border-radius-small;
  border: 1px solid $gallery-1;
  &-image { }
  &-title {
    margin-top: 15px;
    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 32px;
      }
    }
    * + p { margin-top: 5px; }
  }
  &-price {
    position: relative;
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px 0;
    font-size: (44 / $base-text-size) * 1em;
    span {
      position: relative;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      color: $gray-darker;
    }
    sup {
      position: absolute;
      top: 40%;
      right: 100%;
      margin-right: 8px;
      font-size: 18px;
      font-weight: 400;
      color: $gray-darker;
    }
    span:empty {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1px;
      width: 40px;
      border-bottom: 3px solid $primary;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      border-top: 1px solid $mishka;
      left: -40px;
      right: -40px;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    @include media-breakpoint-up(md) {
      padding: 23px 0 26px;
    }
  }
  &-content {
    p {
      padding: 0 15%;
    }
  }
  &-foot { margin-top: 35px; }
  @include media-breakpoint-up(lg) {
    padding: 45px 0;
  }
  @include media-breakpoint-up(xxl) {
    padding: 55px 0 65px;
  }
  &-primary {
    border-color: $primary;
  }
}
