//Posts
.post-classic {
}

.post-header {
}

.post-content {
}

.post-title {
  margin-top: 12px;
}

.post-meta {
  margin-top: 20px;
  font-size: 18px;
  > a {
    &, &:focus, &:active {
      color: inherit;
    }
    &:hover {
      color: $primary;
    }
  }
  > time {
    color: $brand-info;
  }
}

.post {
  a {
    > img {
      @include transition(.3s ease-out all);
      &:hover {
        box-shadow: $shadow-area-lg;
        transform: scale(1.05);
      }
    }
  }
  .btn {
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      margin-top: 32px;
    }
  }
  * + p {
    margin-top: 16px;
  }
}

.news-post {
  border: 1px solid $gallery-1;
  &-header {
    position: relative;
    img {
      width: 100%;
    }
    .thumbnail-default {
      position: relative;
      overflow: hidden;
      &:before {
        content: '\e8b6';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $material-icons;
        font-size: 100px;
        line-height: 1;
        color: rgba(255, 255, 255, 0.3);
        background: rgba(32, 47, 57, 0.2);
        @include transition(.6s ease all);
        transform: scale(1.2);
        pointer-events: none;
        will-change: transfrom;
        visibility: hidden;
        opacity: 0;
        @include media-breakpoint-up(md) {
          font-size: 192px;
        }
      }
      &:hover {
        &:before {
          visibility: visible;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    .label-list {
      margin-top: 20px;
      @include group(5px, 5px);
      @include media-breakpoint-up(sm) {
        font-size: 0;
      }
      @include media-breakpoint-up(md) {
        text-align: right;
        padding-left: 20px;
        position: absolute;
        top: 30px;
        right: 30px;
        margin-top: 0;
      }
      @include media-breakpoint-up(lg) {
        top: 40px;
        right: 40px;
      }
    }
  }
  &-body {
    padding: 30px 15px;
    @include media-breakpoint-up(lg) {
      padding: 55px 60px;
    }
    &-md {
      @include media-breakpoint-up(lg) {
        padding: 45px 60px;
      }
    }
  }
  &-written,
  &-comments {
    padding: 40px 15px;
    @include media-breakpoint-up(md) {
      padding: 40px 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 30px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 40px 120px 40px 60px;
    }
  }
  &-written {
    color: $white;
    background-color: $gray-darker;
    #{headings()} {
      color: $white;
      a {
        &, &:focus, &:active {
          color: $white;
        }
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &-comments {
    position: relative;
    background-color: $wild-sand;
    @media (max-width: $screen-sm-min - 1) {
      .unit-body {
        margin-bottom: 15px;
      }
    }
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    .replay {
      position: absolute;
      display: block;
      margin-bottom: 10px;
      font-size: 22px;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 30px;
        bottom: auto;
        left: auto;
        right: 30px;
      }
      &, &:focus, &:active {
        color: $primary;
      }
      &:hover {
        color: $gray-darker;
      }
    }
    &:nth-child(even) {
      background-color: transparent;
      @include media-breakpoint-up(md) {
        margin-left: 40px;
      }
      @include media-breakpoint-up(xxl) {
        margin-left: 85px;
      }
    }
    #{headings()} {
      a {
        &, &:focus, &:active {
          color: $primary;
        }
        &:hover {
          color: $gray-darker;
        }
      }
    }
    * + p {
      margin-top: 15px;
    }
  }
  & + .news-post {
    margin-top: 40px;
    @include media-breakpoint-up(lg) {
      margin-top: 48px;
    }
  }
  .list-inline + p,
  .list + p {
    margin-top: 15px;
  }
  * + .list-ordered {
    margin-top: 35px;
  }
  p + h5,
  h5 + h5 {
    margin-top: 35px;
    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }
  h4 + p {
    margin-top: 20px;
  }
  * + .btn {
    margin-top: 25px;
  }
}

.ios, .tablet, .iphone, .mobile, .android {
  .thumbnail-default {
    &:before {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
}

.news-post-reverse {
  @include media-breakpoint-up(xxl) {
    .news-post-header,
    .news-post-body {
      position: relative;
      display: table-cell;
      width: 50%;
      vertical-align: top;
    }
    .news-post-header {
    }
    .news-post-body {
      padding: 25px 60px;
      vertical-align: middle;
    }
  }

}