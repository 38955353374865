/*
* @subsection   RD Google Map
*
* @description  Describes style declarations for RD Google Map extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.0.0
*/
$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 550px;
$map-xl-height: 606px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: $map-xs-height;
  }

  @include media-breakpoint-up(md) {
    height: $map-sm-height;
  }

  @include media-breakpoint-up(xxl) {
    height: $map-xl-height;
  }
}

.gm-style-iw > div > div {
  padding-right: 10px;
  font-size: 13px;
}
