//
// Variables
// --------------------------------------------------
@import "bootstrap/variables";

//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$black: #000;
$gray-darker: #202f39;
$gray-dark: #333;
$gray: #939393;
$gray-light: #777777;
$gray-lighter: #96a3ab;

$primary: #e92c0c;
$brand-success: #5cb85c;
$brand-info: #1fc9be;
$brand-warning: #f0ad4e;
$brand-danger: $primary;

$alto: #d3d3d3;
$gallery: #efefef;
$mishka: #e0e0e8;
$gallery-1: #ebebeb;
$wild-sand: #f6f6f6;
$silver-chalice: #9f9f9f;

$white: #fff;

//== Scaffolding
//
//## Settings for some of the most global styles.

$content-bg: $white;
$header-bg: $white;
$footer-bg: $gray-darker;

//** Background color for `<body>`.
$body-bg: #fff;
//** Global text color on `<body>`.
$body-color: $gray;

//** Global textual link color.
$link-color: $primary;
//** Link hover color set via `darken()` function.
$link-hover-color: $body-color;
//** Link hover decoration.
$link-hover-decoration: none;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Import fonts

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: 'Droid Serif', Georgia, "Times New Roman", Times, serif;
$font-family-serif-1: 'Merriweather', Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base: $font-family-serif;
$font-family-sec: $font-family-serif-1;

// Template width (w/o units)
$template-width: 1920;

// Template width var for resizing
$template-width-min: ($template-width+1)*1px;
$layout-boxed-width: 1730px;

// Base FZ & LH (w/o units)
$base-text-size: 16;
$base-lh-size: 23;

$base-vm-size: (($base-text-size*100)/$template-width)*1vw;

$font-size-base: $base-text-size * 1px;
$font-size-lg: 18px; // ~18px
$font-size-sm: 14px; // ~14px

$font-weight-base: 400;

//PSD FZ headers /FOR EDIT (w/o units)
$Size-h1: 66;
$Size-h2: 46;
$Size-h3: 36;
$Size-h4: 26;
$Size-h5: 20;
$Size-h6: 18;

//PSD LH headers /FOR EDIT (w/o units)
$Lh-h1: 76.56;
$Lh-h2: 59.38;
$Lh-h3: 45;
$Lh-h4: 33.8;
$Lh-h5: 30;
$Lh-h6: 26;

//Calculating FZ headers
$h1-font-size: $Size-h1*1px;
$h2-font-size: $Size-h2*1px;
$h3-font-size: $Size-h3*1px;
$h4-font-size: $Size-h4*1px;
$h5-font-size: $Size-h5*1px;
$h6-font-size: $Size-h6*1px;

//** line-height for headers /FOR EDIT
// $font-lh-h_: floor((PSD l-h / $h1-font-size));

$h1-line-height: ($Lh-h1 / $Size-h1);
$h2-line-height: ($Lh-h2 / $Size-h2);
$h3-line-height: ($Lh-h3 / $Size-h3);
$h4-line-height: ($Lh-h4 / $Size-h4);
$h5-line-height: ($Lh-h5 / $Size-h5);
$h6-line-height: ($Lh-h6 / $Size-h6);

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: ($base-lh-size / $base-text-size); // l-h/fz-base
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 400;
$headings-line-height: 1.1;
$headings-color: inherit;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "../fonts/";

//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
$icon-font-awesome: "fontawesome-webfont";
$icon-material-design: "materialdesignicons-webfont";

//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";
$icon-font-awesome-id: "fontawesomeregular";
$icon-material-design-id: "materialdesigniconsregular";

$fa: 'FontAwesome', sans-serif;
$material-icons: 'Material Icons', sans-serif;
$material-design: 'material-design', sans-serif;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 7px;
$padding-base-horizontal: 23px;

$padding-large-vertical: $padding-base-vertical*1.2;
$padding-large-horizontal: $padding-base-horizontal*1.2 ;

$padding-small-vertical: $padding-base-vertical*0.8;
$padding-small-horizontal: $padding-base-horizontal*0.8;

$padding-xs-vertical: $padding-base-vertical*0.4;
$padding-xs-horizontal: $padding-base-horizontal*0.4;

$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

$border-radius: 0;
$border-radius-large: 6px;
$border-radius-small: 3px;

$border-width-base:         2px;
$border-width-large:        4px;
$border-width-small:        1px;

//
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px
);

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: $white;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Lists
$list-xs-offsets: 0;
$list-sm-offsets: 6px;
$list-offsets: 11px;
$list-lg-offsets: 20px;
$list-xl-offsets: 35px;

//== List Inline
$list-inline-xs-offsets: 6px;
$list-inline-sm-offsets: 10px;
$list-inline-offsets: 20px;
$list-inline-lg-offsets: 28px;
$list-inline-xl-offsets: 35px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: #ddd;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-size: 16;
$btn-lh-size: 24;

$btn-font-weight: 400;

$btn-default-color: $white;
$btn-default-bg: $black;
$btn-default-border: $black;

$btn-primary-color: $white;
$btn-primary-bg: $primary;
$btn-primary-border: $primary;

$btn-success-color: $white;
$btn-success-bg: $brand-success;
$btn-success-border: $brand-success;

$btn-info-color: $white;
$btn-info-bg: $brand-info;
$btn-info-border: $brand-info;

$btn-warning-color: $white;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $brand-warning;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $brand-danger;

$btn-link-disabled-color: $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;

//
// Forms
//
$form-input-height: 46px;
$form-input-lg-height: 60px;
$form-input-sm-height: 40px;

$form-input-color: $silver-chalice;
$form-input-color-placeholder: $form-input-color;

$form-input-background: $wild-sand;
$form-input-border-color: $form-input-background;
$form-input-border-radius: $border-radius;

$form-input-font-size: 16px;
$form-input-line-height: 28px;
$form-input-font-weight: 400;

$form-input-border: 1px solid $form-input-border-color;
$form-input-padding-horizontal: 10px;
$form-input-line-height: round($form-input-font-size * 1.7);

$form-input-padding-vertical: round(($form-input-height - $form-input-line-height) / 2) + 1px;
$form-input-padding: $form-input-padding-vertical $form-input-padding-horizontal;

@if ($form-input-border != none) {
	$form-input-padding-vertical: round(($form-input-height - $form-input-line-height - (nth($form-input-border, 1) * 2)) / 2);
	$form-input-padding: $form-input-padding-vertical $form-input-padding-horizontal;
}

$form-textarea-default-height: 135px;
$form-textarea-default-min-height: $form-input-height;
$form-textarea-default-max-height: round($form-textarea-default-height * 1.7);

$form-feedback-focus-color: $primary;
$form-feedback-valid-color: $brand-success;
$form-feedback-invalid-color: $brand-danger;

//** `<input>` background color
$form-input-background: $wild-sand;
//** `<input disabled>` background color
$form-input-background-disabled: $gray-lighter;

//** Text color for `<input>`s
$form-input-color: $silver-chalice;
//** `<input>` border color
$form-input-border-color: transparent;

//** Font size for input
$form-input-font-size: 16px;

//** Default `.form-input` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$form-input-border-color-radius: 0;
//** Large `.form-input` border radius
$form-input-border-color-radius-large: $border-radius-large;
//** Small `.form-input` border radius
$form-input-border-color-radius-small: $border-radius-small;

//** Border color for inputs on focus
$form-input-border-color-focus: $form-input-border-color;

//** Placeholder text color
$form-input-color-placeholder: $form-input-color;
$form-input-color-placeholder-focus: $form-input-color;

//** Default `.form-input` height
$form-input-height: 56px;
$form-input-font-weight: 400;
$form-input-line-height: (26 / 16);
$form-input-border-radius: 0;
//** Large `.form-input` height
$input-height-large: (ceil($font-size-lg * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-input` height
$input-height-small: (floor($font-size-sm * $line-height-small) + ($padding-small-vertical * 2) + 2);

$form-input-padding-vertical: 15px;
$form-input-padding-horizontal: 15px;

//** `.form-wrap` margin
$form-wrap-margin-bottom: 18px;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $form-input-border-color;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//** Textarea default
$form-textarea-default-height: 170px;
$form-textarea-default-min-height: 170px;
$form-textarea-default-max-height: 170px;

//== Dropdowns
//

// $dropdown-font-size: 14;
// $dropdown-lh-size: 36;

//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: $form-input-background;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, .15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: $component-active-color;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $component-active-bg;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;


//== Select
//

//** single selection
$select2-single-selection-height:                 23px;
$select2-single-selection-rendered-padding-left : 8px;
$select2-single-selection-rendered-padding-right: 20px;

//** dropdown
$select2-dropdown-arrow-color:                    $body-color;
$select2-single-selection-dropdown-background:    $form-input-background;
$select2-single-selection-dropdown-border:        1px solid $primary;
$select2-dropdown-icon:                           '\e5cf';
$select2-dropdown-icon-family:                    $material-icons;

//** multiple selection
$select2-multiple-selection-results-group-size:   12px;
$select2-multiple-selection-results-group-weight: 400;

//** clear selection
$select2-clear-selection-offset-right:            10px;
$select2-clear-selection-weight:                  700;
$select2-clear-selection-hover-color:             #333;
$select2-clear-selection-color:                   $select2-dropdown-arrow-color;

//** choice remove
$select2-single-selection-choice-remove-color:    #999;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// Extra Large screen / 4k desktop
$screen-xl: 1800px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Define media prefix
$medias: (0: null, $screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar
//
//##

// $navbar-font-size: 16;
// $navbar-lh-size: 18;

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%);
$navbar-inverse-bg: #222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: lighten($gray-light, 15%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-tabs-border-color: #ddd;

$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: #ddd;

$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

//== RD Audio Player
//
//##

$rd-audio-player-controls-color:                      $gray-darker;
$rd-audio-player-controls-padding:                    8px 10px;
$rd-audio-player-controls-xs-padding:                 34px 10px;
$rd-audio-player-controls-md-padding:                 53px 65px 61px;
//** icons sizing
$rd-audio-player-icon-audio-size:                     16px;
$rd-audio-player-icon-audio-xs-size:                  18px;
//** audio volume
$rd-audio-player-icon-volume:                         '\f026';
//** audio volume bar
$rd-audio-player-audio-volume-bar-background:         rgba(255,255,255,0.5);
//** audio play pause
$rd-audio-player-icon-play-pause:                     '\f04c';
$rd-audio-player-icon-audio-play-pause-size:          20px;
$rd-audio-player-icon-audio-play-pause-width:         28px;
$rd-audio-player-icon-audio-play-pause-height:        28px;
//** 768
$rd-audio-player-icon-audio-play-pause-sm-size:       20px;
$rd-audio-player-icon-audio-play-pause-sm-width:      36px;
$rd-audio-player-icon-audio-play-pause-sm-height:     36px;
//** audio title
$rd-audio-player-icon-audio-title-wrap-size:          13px;
$rd-audio-player-icon-audio-title-wrap-weight:        700;
$rd-audio-player-icon-audio-title-wrap-line-height:   18px;
$rd-audio-player-icon-audio-title-wrap-offsets:       0 10px;
//** audio title
$rd-audio-player-icon-audio-time-size: 13px;
//** audio progress bar
$rd-audio-player-audio-progress-bar-background:       rgba(255,255,255,0.5);
//** audio playlist
$rd-audio-player-playlist-wrap-background:            $white;
$rd-audio-player-playlist-title-weight:               900;
$rd-audio-player-playlist-title-size:                 13px;
$rd-audio-player-playlist-title-padding:              7px 18px;
$rd-audio-player-playlist-title-border-bottom:        1px solid $gray;
//** audio playlist item
$rd-audio-player-playlist-item-padding:               8px 15px;
$rd-audio-player-playlist-item-xs-padding:            8px 70px 8px 18px;
$rd-audio-player-playlist-item-weight:                700;
$rd-audio-player-playlist-item-size:                  13px;
$rd-audio-player-playlist-item-line-height:           24px;
//** audio playlist item icon
$rd-audio-player-playlist-item-icon:                  '\f4fc';
$rd-audio-player-playlist-item-icon-weight:           400;
$rd-audio-player-playlist-item-icon-size:             24px;
$rd-audio-player-playlist-item-icon-line-height:      24px;



//== Pagination
//
//##

$pagination-color: $link-color;
$pagination-bg: #fff;
$pagination-border: #ddd;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $primary;
$pagination-active-border: $primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;

//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-lighter;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5));

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #000;
$tooltip-opacity: .9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: #fff;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, .2);
//** Popover fallback border color
$popover-fallback-border-color: #ccc;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%);

//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: #fff;
//** Default text color of a linked label
$label-link-hover-color: #fff;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: #fff;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, .2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

//** Modal backdrop background color
$modal-backdrop-bg: #000;
//** Modal backdrop opacity
$modal-backdrop-opacity: .5;
//** Modal header border color
$modal-header-border-color: #e5e5e5;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius;
$alert-link-font-weight: bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #f5f5f5;
//** Progress bar text color
$progress-bar-color: #fff;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius;

//** Default progress bar color
$progress-bar-bg: $primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: #fff;
//** `.list-group-item` border color
$list-group-border: #ddd;
//** List group border radius
$list-group-border-radius: $border-radius;

//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

//** Text color of disabled list items
$list-group-disabled-color: $gray-light;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;

//== Panels
//
//##

$panel-bg: #fff;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius;

//** Border color for elements within panels
$panel-inner-border: #ddd;
$panel-footer-bg: #f5f5f5;

$panel-default-text: $gray-dark;
$panel-default-border: #ddd;
$panel-default-heading-bg: #f5f5f5;

$panel-primary-text: #fff;
$panel-primary-border: $primary;
$panel-primary-heading-bg: $primary;

$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 4px;
//** Thumbnail background color
$thumbnail-bg: $body-bg;
//** Thumbnail border color
$thumbnail-border: #ddd;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $body-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;

//== Wells
//
//##

$well-bg: #f5f5f5;
$well-border: darken($well-bg, 7%);

//== Badges
//
//##

$badge-color: #fff;
//** Linked badge text color on hover
$badge-link-hover-color: #fff;
$badge-bg: $gray-light;

//** Badge text color in active nav link
$badge-active-color: $link-color;
//** Badge background color in active nav link
$badge-active-bg: #fff;

$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: #f5f5f5;
//** Breadcrumb text color
$breadcrumb-color: #ccc;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/";

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;

//== Close
//
//##

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;

//== Code
//
//##

$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint;
//** Horizontal line color.
$hr-border: $gallery;

//== Shadows
//
//##

$shadow-area-ambient: 0px 0px 1px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xxs: 1px 1px 1px rgba(0, 0, 0, 0.15);
$shadow-area-xs: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
$shadow-area-sm: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
$shadow-area-md: 0 3px 11px 0 rgba(0, 0, 0, 0.15);
$shadow-area-lg: 6px 8px 24px 0 rgba(32, 47, 57, .2);
$shadow-area-xl: 0 12px 36px 0 rgba(0, 0, 0, .15);

$shadow-area-custom: 3px 4px 18px 0 rgba($primary, .52);

//== RD Search 404, Search Result
//
//##

$rd-search-border: 1px solid $body-color;
$rd-search-font-size: $font-size-base;
$rd-search-color: $body-color;
//Submit
$rd-search-submit-width: 42px;
$rd-search-submit-height: 23px;
$rd-search-submit-font-size: 30px;
$rd-search-submit-color: $primary;
$rd-search-submit-background: transparent;
$rd-search-submit-color-hover: $body-color;
$rd-search-submit-background-hover: transparent;

//== RD Navbar
//
//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $gray-dark;
$rd-navbar-background: $white;
$rd-navbar-shadow: none;
$rd-navbar-width: 1758px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $gray-darker;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $primary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 210px;
$rd-navbar-dropdown-offset: 10px;
$rd-navbar-dropdown-padding: 0;
$rd-navbar-dropdown-background: $gray-darker;
$rd-navbar-dropdown-item-padding: 14px 19px;
$rd-navbar-dropdown-item-font-size: 14px;
$rd-navbar-dropdown-item-color: $white;
$rd-navbar-dropdown-item-background: $gray-darker;
$rd-navbar-dropdown-item-hover-color: $brand-info;
$rd-navbar-dropdown-item-hover-background: $gray-darker;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;
//Submenu
$rd-navbar-static-submenu-togle-fz: 16px;
$rd-navbar-static-submenu-togle-color: $rd-navbar-dropdown-item-color;
$rd-navbar-static-submenu-togle-hover-color: $rd-navbar-dropdown-item-hover-color;


//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $gray-darker;
$rd-navbar-megamenu-item-color: $white;
$rd-navbar-megamenu-item-background: transparent;
$rd-navbar-megamenu-item-hover-color: $primary;
$rd-navbar-megamenu-item-hover-background: transparent;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-search-toggle-preset: "search-preset-1";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-font-size: $rd-navbar-min-font-size;
$rd-navbar-fixed-panel-color: $white;
$rd-navbar-fixed-toggle-color: $white;
$rd-navbar-fixed-panel-background: $gray-darker;
$rd-navbar-fixed-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17);

//RD Navbar Static
$navbar-static-inner-padding: 12px 15px;
$navbar-static-nav-indent: 16px;
$rd-navbar-static-padding-link: 40px 20px 47px;
$rd-navbar-static-font-size-link: 18px;
$rd-navbar-static-line-height-link: 1.2;
$rd-navbar-static-font-weight-link: 400;
$rd-navbar-static-letter-spacing-link: 0;
//Submenu
$rd-navbar-static-submenu-togle-fz: 16px;

//RD Navbar fullwidth indents
$navbar-fullwidth-nav-indent: 20px;
