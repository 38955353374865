//
// Nav custom
// 

// 
// Table of Contents:
// 
// Tabs Line 
// Tabs Corporate
//

// Corporate style
$tabs-corporate-color: $white;
$tabs-corporate-background: $gray-darker;
$tabs-corporate-active-color: $white;
$tabs-corporate-active-background: $primary;
$tabs-corporate-border: #e5e7e9;

// Base styles
.tabs-custom {
	text-align: left;
	.nav-tabs {
		font-size: 0;
		line-height: 0;
		word-spacing: 0;
		border: 0;

		&:before,
		&:after {
			display: none;
		}
	}

	.nav-item {
		float: none;
		border: 0;
		cursor: pointer;
		transition: .33s all ease;
	}

	.nav-link {
		margin: 0;
		border-radius: 0;
		border: 0;
	}

	.nav-link.active {
		cursor: default;
		border: 0;
	}
}

.tab-content > .tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content > .active {
	visibility: visible;
	height: auto;
	overflow: visible;
}

// Offsets
* + .tabs-horizontal.tabs-corporate {
	margin-top: 25px;
}

* + .tabs-vertical.tabs-corporate {
	margin-top: 40px;
}

@include media-breakpoint-up(md) {
	* + .tabs-vertical.tabs-corporate {
		margin-top: 60px;
	}
}

@include media-breakpoint-up(xl) {
	* + .tabs-vertical.tabs-corporate {
		margin-top: 80px;
	}
}

// Tabs corporate style
.tabs-corporate {
	.nav-tabs {
		position: relative;
		display: block;
	}

	.nav-tabs {
		margin-top: -1px;
		margin-left: -1px;
	}

	.nav-item {
		display: block;
		margin-left: 1px;
		margin-top: 2px;
	}

	.nav-item {

	}

	.nav-link {
		padding: 10px 10px;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.4;
		color: $tabs-corporate-color;
		background: $tabs-corporate-background;
		text-align: center;
		vertical-align: middle;
	}

	.nav-link:hover,
	.nav-link.active {
		color: $tabs-corporate-active-color;
		background: $tabs-corporate-active-background;
		border-color: $tabs-corporate-active-background;
	}

	.tab-content {
		padding: 30px 0 0;
	}
}

@include media-breakpoint-up(md) {
	//
	// Horizontal tabs
	// 
	.tabs-horizontal {
		&.tabs-corporate {
			.nav-tabs {
				margin-top: -5px;
				margin-left: -3px;
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				flex-wrap: wrap;
			}

			.nav-item {
				flex-grow: 1;
				margin-left: 3px;
				margin-top: 5px;
			}

			.nav-link {
				display: block;
				position: relative;
				z-index: 1;
				padding: 15px;
				font-size: 16px;
				min-width: 130px;
				color: $tabs-corporate-color;
				background-color: $tabs-corporate-background;
			}

			.nav-link.active,
			.nav-link:hover {
				color: $tabs-corporate-active-color;
				background-color: $tabs-corporate-active-background;
			}

			.tab-content {
				border: 1px solid $gallery-1;
				padding: 30px 25px 42px;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	//
	// Horizontal tabs
	// 
	.tabs-horizontal {

		// Tabs Corporate
		&.tabs-corporate {
			.tab-content {
				padding: 30px 65px 42px;
			}
		}
	}

	// 
	// Vertical tabs
	// 
	.tabs-vertical {

		// Tabs corporate
		&.tabs-corporate {
			.tab-content {
				padding: 0 0 0 45px;
			}
		}
	}
}
