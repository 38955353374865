//
// Custom Buttons Styles
// --------------------------------------------------

// Base styles
// --------------------------------------------------
button {
  border: none;
}

html .page {

  .btn {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1;
    display: inline-block;
    max-width: 100%;
    padding: 17px 30px;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 2px solid;
    transition: .3s ease-out;
    border-radius: $border-radius-small;
    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }

    &,
    &:active,
    &.active {
      &:focus,
      &.focus {
      }
    }

    &:hover,
    &:focus,
    &.focus {
    }

    &:active,
    &.active {
      box-shadow: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
    }
  }

  // Alternate buttons
  // --------------------------------------------------

  .btn-default {
    &, &:focus, &:active {
      color: $body-color;
      border: 1px solid $body-color;
      background-color: transparent;
    }
    &:hover {
      color: $white;
      border-color: $body-color;
      background-color: $body-color;
    }
  }

  .btn-primary {
    &, &:focus, &:active {
      color: $white;
      border-color: $primary;
      background-color: $primary;
      box-shadow: 3px 4px 18px 0 $primary;
    }
    &:hover {
      color: $white;
      border-color:  $gray-darker;
      background-color: $gray-darker;
      box-shadow: 3px 4px 18px 0 $gray-darker;
    }
  }

  .btn-info {
    &, &:focus, &:active {
      color: $white;
      border-color: $brand-info;
      background-color: $brand-info;
      box-shadow: 3px 4px 18px 0 $brand-info;
    }
    &:hover {
      color: $white;
      border-color:  $gray-darker;
      background-color: $gray-darker;
      box-shadow: 3px 4px 18px 0 $gray-darker;
    }
  }

  // Success appears as green
  .btn-success {
  }

  // Info appears as blue-green
  .btn-info {
  }

  // Warning appears as orange
  .btn-warning {
  }

  // Danger and error appear as red
  .btn-danger {
  }

  // Button Sizes
  // --------------------------------------------------

  .btn-xs {
  }

  .btn-sm {
  }

  .btn-lg {
  }

  // Button Shapes
  // --------------------------------------------------

  .btn-rect {
    border-radius: 0;
  }

  // Button Icon styles
  // --------------------------------------------------

  .btn {

    &.btn-icon {
      padding-left: 17px;
      padding-right: 17px;

      .icon {
        font-size: 28px;
        line-height: 28px;
        vertical-align: middle;
        transition: 0s;
      }

      &-left {
        .icon {
          float: left;
          padding-right: 10px;
        }
      }

      &-right {
        .icon {
          float: right;
          padding-left: 10px;
        }
      }

    }
  }

}