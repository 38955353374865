//
// Scaffolding
// --------------------------------------------------

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  outline: none;
}

// Body styles
// --------------------------------------------------

body {
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 1.5;
  -webkit-text-size-adjust: none;
  color: $body-color;
  background-color: $body-bg;
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: $line-height-base;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }
}

// Page styles
// --------------------------------------------------

.page {
  overflow: hidden;
  background-color: $content-bg;
}

//Page Header
.page-head {
  position: relative;
  z-index: 1000;
}

//Page Content

.page-content {
}

//Page Footer

.page-footer {
  padding-top: 55px;
  padding-bottom: 55px;
  background-color: $footer-bg;
  color: $white;
  #{headings()} {
    color: $white;
  }
  address {
    color: $alto;
    p {
      @include media-breakpoint-up(lg) {
        line-height: 30px;
      }
    }
  }
  .list {
    > li {
      &.active {
        a {
          &, &:focus, &:active, &:hover {
            color: $white;
          }
        }
      }
    }
  }
  a {
    &, &:focus, &:active {
      color: $brand-info;
    }
    &:hover, &.active {
      color: $white;
    }
    &.icon {
      &, &:focus, &:active {
        color: $white;
      }
      &:hover {
        color: $brand-info;
      }
    }
    &[href='callto:#'],
    &[href='mailto:#']{
      display: inline-block;
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .list-terms-footer {
    color: $alto;
    dt,dd {
      display: inline-block;
      font-weight: 400;
    }
    dd {
      margin-left: 5px;
    }
    * + dl {
      margin-top: 15px;
    }
  }
  * + address {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  * + .list-inline {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 25px;
    }
  }
  * + .list-terms-footer {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 35px;
    }
  }
  p + a {
    margin-top: 15px;
  }
  h5 + .list {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 33px;
    }
  }
  p + p {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  .copyright {
    margin-top: 0;
    font-family: $font-family-sec;
    font-size: $font-size-sm;
    color: $gray-lighter;
    a {
      &, &:focus, &:active {
        color: $white;
      }
      &:hover {
        color: $gray-lighter;
      }
    }
  }
  &.page-footer-style-2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}



// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

// Links

// Links
a {
  &,
  &:active,
  &:focus {
    color: $link-color;
    text-decoration: none;
  }
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}

// Horizontal rules

hr {
  margin-top: 54px;
  margin-bottom: 54px;
  border-top: 1px solid $hr-border;
  @include media-breakpoint-up(xxl) {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  &.divider-lg {
    margin-top: 50px;
    margin-bottom: 40px;
  }
  &.divider-xl {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

