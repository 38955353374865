/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;

  // RD Navbar Brand
  .rd-navbar-brand {
    display: block;
    text-align: left;
    position: fixed;
    top: 6px;
    left: 56px;
    right: 112px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 46px;
    height: 48px;
    z-index: 17;

    .brand-name {
      color: $white;
    }
  }
  .rd-navbar-nav {
    width: $rd-navbar-nav-min-width;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    font-size: 16px;
    line-height: 34px;
    color: $rd-navbar-color;
    background: $rd-navbar-fixed-panel-background;
    box-shadow: 0 0 11px 2px rgba(0, 0, 0, 0.17);
    z-index: 998;
    padding: 10px 0;

    &:before,
    &:after {
      content: '';
      display: block;
      height: $rd-navbar-min-height;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: lighten($rd-navbar-background, 10%);
      border: none;
      border-radius: 0;
      opacity: .2;
    }

    &::-webkit-scrollbar-track {
      background: $rd-navbar-background;
      border: none;
      border-radius: 0;
    }

    li {
      position: relative;

      li > a {
        padding-left: 20px;
      }

      > a {
        position: relative;
        display: block;
        padding: 5px 45px 5px 15px;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &.active > a,
      &:hover > a {
        color: $white;
        background: $primary;
      }

      &.focus > a, > a:hover {
        color: $white;
        background: $primary;
      }

      & + li, > img + a, > a + a, > a + ul {
        margin-top: ($rd-navbar-min-height - $rd-navbar-min-line-height) / 2;
      }
    }

    // RD Navbar Dropdown
    .rd-navbar-dropdown {
      > li > a {
        padding-left: 25px;
      }

      ul > li > a {
        padding-left: 30px;
      }
    }

  }

  // RD Navbar Panel
  .rd-navbar-panel {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
    height: $rd-navbar-fixed-height;
    color: $rd-navbar-fixed-panel-color;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: $rd-navbar-fixed-shadow;
      background: $rd-navbar-fixed-panel-background;
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
    position: fixed;
    top: 4px;
    left: 3px;
    padding: 0;
  }

  .rd-navbar-collapse {
    &-toggle {
      display: inline-block;
      position: fixed;
      top: 4px;
      right: 3px;
      padding: 0;
    }
    &.active {

    }
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: fixed;
    top: -56px;
    left: 0;
    width: 232px;
    padding: 112px 0 56px;
    bottom: -56px;
    color: $white;
    background: $rd-navbar-background;
    z-index: 15;
    transform: translateX(-120%);

    &.active {
      transform: translateX(0);
    }
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }

    li {
      > a {
        display: block;
        padding: 11px 56px 11px 16px;
        color: $rd-navbar-fixed-panel-color;
      }

      &.opened > a, a:hover {
        color: $white;
        background-color: $primary;
      }

      &.active {
        .rd-navbar-submenu-toggle {
          &:after {
            color: $white;
          }
        }

        > a {
          //color: $white;
          //background: $primary;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 32px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 35px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  .rd-navbar-collapse {
    display: none;
  }

  .rd-navbar-call {
    display: none;
  }

  a[href='mailto:#'] {
    display: inline-block;
    margin-left: 3px;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
  }

  .rd-navbar-inner-bottom {
    //display: none;
    .brand-name {
      display: none;
    }
  }

  .form-label {
    margin-bottom: 0;
  }

  .rd-navbar-panel-aside {
    position: fixed;
    top: 56px;
    left: auto;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 220px;
    padding: 20px 10px 25px 25px;
    background-color: $white;
    visibility: hidden;
    opacity: 0;
    box-shadow: $shadow-area-sm;
    @include transition(.3s ease-out all);
    a {
      margin-top: 15px;
      & + a {
        margin-top: 0;
      }
    }
    a.fixed-link {
      &:after {
        content: '\e315';
        display: inline-block;
        vertical-align: middle;
        font-family: "Material Icons", sans-serif;
        font-size: 20px;
        text-decoration: none;
      }
    }
    a[href='callto:#'] {
      &:before {
        position: relative;
        top: -2px;
        margin-right: 5px;
        content: "\e551";
        display: inline-block;
        vertical-align: middle;
        font-family: 'Material Icons';
        font-weight: 400;
        font-style: normal;
        font-size: inherit;
      }
    }
    a[href='mailto:#'] {
      display: none;
      &:before {
        content: "\e1a5";
        display: inline-block;
        vertical-align: middle;
        font-family: "material-design";
        font-size: inherit;
        font-weight: 400;
        font-style: normal;
        margin-right: 5px;
      }
    }
    a.icon-white {
      &, &:focus, &:active {
        color: $primary;
      }
      &:hover {
        color: $gray-darker;
      }
    }
    &.active {
      visibility: visible;
      opacity: 1;
    }
    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      justify-content: space-around;
      .list-inline {
        margin-bottom: -10px;
      }
    }
    @include media-breakpoint-up(lg) {
      .list-inline {
        margin-bottom: -28px;
      }
    }
  }



  .list-inline {
    margin-top: 10px;
    order: 4;
    display: none;
  }

  a {
    order: 3;
    &:hover {
      color: $brand-info;
    }
  }

  // RD Navbar Search
  .rd-navbar-search {
    width: 100%;

    order: 1;
    .form-label,
    .form-input {
      color: $primary;
      font-size: 16px;
    }
    .form-label {
      top: 50%;
      transform: translateY(-50%);
      margin-bottom: 0;
    }
    form {
      button[type="submit"] {
        height: 46px;
        line-height: 46px;
        z-index: 1000;
        &, &:focus, &:active {
          color: $primary;
        }
        &:hover {
          color: $gray-darker;
        }
      }
    }

    #search-results {
      display: none;
    }

    &.active {
      .form-wrap {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    &-form,
    &-toggle {
      right: 4px;
    }

    &-toggle {
      display: block;
      position: fixed;
      top: 4px;
      right: 56px;
      font-family: 'Material Icons';
      width: 48px;
      height: 48px;
      z-index: 18;
      color: $rd-navbar-color;
    }

    .form-wrap {
      padding-top: 0;
      height: 46px;
      background: $rd-navbar-background;
      z-index: 17;
      @extend %rd-navbar-transition;
      @include transition(.3s ease-out all);

      input {
        width: 100%;
        height: 46px;
        display: block;
      }
    }



    .rd-navbar-live-search-results {
      top: $rd-navbar-fixed-height;
    }

    &-form {
      top: 0;
      z-index: 1000;
      left: 50px;
      right: 0;
      height: $rd-navbar-fixed-height;
      label {
        display: none;
      }

      &-input {
        padding: 8px 40px 8px 10px;
        height: $rd-navbar-fixed-height - 16px;
        position: relative;
        background-color: $white;
        transition: .3s all ease;
        z-index: 1;
        width: 100%;
        border: 0;
        input {
        }
      }

      &-submit {
        position: absolute;
        top: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
        right: 4px;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }

      }
    }

    &-toggle {
      &.active {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.active {
      .rd-navbar-search-form-input,
      .rd-navbar-search-form-submit {
        opacity: 1;
        visibility: visible;
      }
    }

    @include media-breakpoint-up(lg) {
      &-form, .rd-navbar-live-search-results {
        max-width: 340px;
      }

      .rd-navbar-live-search-results {
        right: 4px;
      }
    }

    @include media-breakpoint-up(sm) {
      .rd-navbar-brand {
        .brand-name {
          font-size: $rd-navbar-fixed-font-size;
        }
      }
    }
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    li {
      &:hover,
      &.focus {
        > a, > a:hover {
          color: $white;
          background: $primary;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > a {
          background: $primary;
          color: $white;
        }
        > .rd-navbar-submenu-toggle {
          color: #fff;
          &::after {
            @include transform (rotate(180deg));
          }
        }
      }

    }

    .rd-navbar-submenu-toggle {
      &::after {
        content: '\f078';
        position: absolute;
        top: 22px;
        right: 0;
        margin-top: -22px;
        width: 65px;
        height: 48px;
        font: 400 14px "FontAwesome";
        line-height: 48px;
        text-align: center;
        transition: 0.4s all ease;
        z-index: 2;
        color: $rd-navbar-fixed-panel-color;
      }
    }
  }


  // RD Navbar States
  &.active {
    .rd-navbar-nav {
      transform: translateX(0);
    }
  }

  &.rd-navbar--on-search {
    @media (max-width: 1199px) {
      .brand-name {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.7);
      }
    }
  }

  &.rd-navbar--is-clone {
    display: none;

    .rd-navbar-panel {
      transform: translateY(-110%);
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-panel {
        transform: translateY(0);
      }
    }
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}

html.rd-navbar-fixed-linked {
  body {
    padding-top: 55px;
  }
  .rd-navbar-wrap {
    height: 1px !important;
  }
}

