/*
* Responsive units
*/

@include unit-responsive(30px, 20px);

@include media-breakpoint-up(xxl) {
  @include unit-responsive(40px, 20px);
}

//
// Unit Spacing
//
.unit-spacing-xs {
  @include unit-spacing(20px, 20px);
}

.unit-spacing-xl {
  @include unit-spacing(30px, 20px);

  @include media-breakpoint-up(xxl) {
    @include unit-spacing(60px, 20px);
  }
}
