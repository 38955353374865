/*
*
* Progress Bars
*/

//
// Linear Progress Bars
//

.progress-linear {
  position: relative;
  text-align: left;

  .progress-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include spacing(10px);
  }

  .progress-bar-linear-wrap {
    height: 17px;
    background: $gallery-1;
  }

  .progress-bar-linear {
    position: relative;
    width: 0;
    height: inherit;
    background: $primary;
    transition: .5s all ease-in-out;
  }

  .progress-value {
    position: absolute;
    right: 8px;
    top: 49%;
    transform: translateY(-50%);
    font-size: 14px;
    font-weight: 700;
    color: $white;

    &::after {
      content: "%";
    }
  }
}

* + .progress-bar-linear-wrap {
  margin-top: 8px;
}

.progress-linear + .progress-linear {
  margin-top: 15px;
}

@include media-breakpoint-up(lg) {
  .progress-linear + .progress-linear {
    margin-top: 25px;
  }
}
