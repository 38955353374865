/*
* Fullwidth Layout
*/

.rd-navbar-fullwidth {
  display: block;

  .rd-navbar-brand,
  .rd-navbar-nav > li > a,
  .rd-navbar-search-toggle {
    position: relative;
    z-index: 2;
  }

  // RD Navbar Submenu
  .rd-navbar-nav {
    width: 100%;
    > li + li {
      margin-left: $navbar-fullwidth-nav-indent;
    }
    > li {
      > a {
        display: block;
        padding: 0 0 10px;
        color: $rd-navbar-nav-color;
        background: $rd-navbar-nav-background;
        line-height: 1.2;
        font-size: 16px;
      }

      &.focus > a,
      > a:hover,
      &.active > a {
        color: $rd-navbar-nav-hover-color;
        background: $rd-navbar-nav-hover-background;
      }
    }

    > .rd-navbar-submenu {
      > ul {
        box-shadow: $rd-navbar-shadow;
      }

      .rd-navbar-submenu {

        // Submenu Arrow
        &.rd-navbar--has-dropdown {
          position: relative;

          .rd-navbar-submenu-toggle {
            color: $white;
            position: absolute;
            right: 15px;
            top: 50%;
            @include transform (translateY(-50%));
            display: inline-block;
            &:before {
              content: '\e315';
              font-family: 'Material Icons';
            }
          }
        }
      }


      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 0;
        visibility: hidden;
        transform: translateY(30px);
      }

      &.focus {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }

      .rd-navbar-submenu.focus > .rd-navbar-dropdown {
        display: block;
      }
    }
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;
    max-width: $rd-navbar-width;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    > li > .rd-navbar-dropdown {
      display: block;
      position: absolute;
      text-align: left;
      margin-top: 10px;
      left: 0;
      width: $rd-navbar-dropdown-width;
      background: $rd-navbar-dropdown-background;
      z-index: 5;
    }

    li.rd-navbar--has-dropdown {
      position: relative;
    }

    li.focus,
    li.opened {
      > .rd-navbar-dropdown, > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    > li {
      display: inline-block;

      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        top: 100%;
        z-index: 15;
      }

      > .rd-navbar-dropdown {

        .rd-navbar-dropdown {
          left: 100%;
          top: 0;
          z-index: 2;
        }
      }
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    background: $rd-navbar-dropdown-background;

    > li > a {
      display: block;
      padding: 2px 20px;
      color: $rd-navbar-dropdown-item-color;
      background: $rd-navbar-dropdown-item-background;
    }

    > li > a:hover {
      color: $rd-navbar-dropdown-item-hover-color;
      background: $rd-navbar-dropdown-item-hover-background;
    }

    > li.focus > a {
      color: $rd-navbar-dropdown-item-active-color;
      background: $rd-navbar-dropdown-item-active-background;
    }
  }

  // RD Navbar Megamenu
  .rd-navbar-megamenu {
    display: table;
    table-layout: fixed;
    left: 10px;
    width: 98%;
    position: absolute;
    text-align: left;
    border-spacing: 31px 25px;
    table-layout: fixed;
    margin-top: 10px;
    max-width: $rd-navbar-width;
    background: $rd-navbar-megamenu-background;
    z-index: 4;

    > li {
      position: relative;
      display: table-cell;

      > p {
        font-weight: 400;
        text-transform: uppercase;
        color: $white;
      }

      > ul {
        padding: 8px 9px;

        li + li {
          margin-top: 5px;
        }

        a {
          display: inline-block;
          color: $rd-navbar-megamenu-item-color;
          background: $rd-navbar-megamenu-item-background;

          &:hover {
            color: $rd-navbar-megamenu-item-hover-color;
            background: $rd-navbar-megamenu-item-hover-background;
          }
        }
      }

      & + li {
        padding-left: 10px;
        &:before {
          content: '';
          position: absolute;
          top: $rd-navbar-megamenu-gutter;
          bottom: $rd-navbar-megamenu-gutter;
          width: 1px;
          right: 100%;
          margin-right: 17px;
          background: lighten($rd-navbar-megamenu-background, 7.450980392156865%);
        }
      }
    }
  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    display: none;

    //IF Cloned
    //position: fixed;
    //left: 0;
    //top: 0;
    //right: 0;
    //z-index: 999;
    //display: block;
    //background-color: $primary;
    //box-shadow: $shadow-area-lg;
    //transform: translateY(-60px);
    //visibility: hidden;
    //@include opacity(0);
    //@include transition(.3s ease-out all);

    &.rd-navbar--is-stuck {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      z-index: 999;
      background: $rd-navbar-background;
      box-shadow: $shadow-area-lg;
      //If Cloned
      //visibility: visible;
      //@include opacity(1);
      //transform: translateY(0);
    }
  }

  // RD Navbar States
  &.rd-navbar--is-stuck {
    .rd-navbar-panel,
    .rd-navbar-collapse {
      display: none;
    }
  }
}