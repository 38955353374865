/*
*
* Material Parallax
* --------------------------------------------------
*/

.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
}

.mobile, .tablet {
  .parallax-container {
    background-attachment: scroll !important;
  }
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
}

.parallax-content {
  position: relative;
  z-index: 1;
}

.parallax-content {

  h3 + h2 {
    margin-top: 15px;
  }

  &-text-white {
    color: $white;

    #{headings()} {
      color: $white;
    }
  }
  &-text-default {
    h2 {
      color: $gray-darker;
    }
  }

  &-inner {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    clip: rect(0, auto, auto, 0);
  }

  &-layer[data-type="media"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &-layer[data-url] {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
  }

  // Styles for Parallax with slider
  &-swiper {
    z-index: 1 !important;
  }
}

.parallax-custom {
  .rd-parallax-layer[data-url] {
    margin-right: -15px;
  }
}